.boton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--btn);
  color: #fff;
  font-weight: 500;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  transition: 0.5s ease all;
  position: relative;
  overflow: hidden;
}

.boton span {
  position: relative;
  z-index: 2;
  transition: 0.3s ease all;
}

/* primer estilo */

.boton.one::after {
  content: "";
  width: 100%;
  height: 300px;
  background: var(--btn-hover);
  position: absolute;
  z-index: 1;
  top: -300px;
  left: 0;
  transition: 0.6s ease-in-out all;
  border-radius: 0px 0px 300px 300px;
}

.boton.one:hover::after {
  top: 0;
}

/* segundo estilo */

.boton.two::after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--btn-hover);
  position: absolute;
  z-index: 1;
  top: -80px;
  left: 0;
  transition: 0.6s ease-in-out all;
}

.boton.two:hover::after {
  top: 0;
}

/* tercer estilo */

.boton.three::after {
  content: "";
  width: 1px;
  height: 1px;
  background: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transition: 0.9s ease-in-out all;
  border-radius: 100px;
  transform-origin: center;
}

.boton.three:hover::after {
  transform: scale(400);
  background: var(--btn-hover);
}

/*boton del formulario */

.formContent__btn {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: var(--btn) !important;
  color: #fff !important;
  font-weight: 500 !important;
  border: none !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
  transition: 0.5s ease all !important;
  position: relative !important;
  overflow: hidden !important;
  width: auto !important;
  padding: 16px 24px !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.boton.five {
  --border: 5px; /* the border width */
  --slant: 0.7em; /* control the slanted corners */
  --color: #000; /* the color */

  font-size: 22px;
  padding: 0.4em 1.2em;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--color);
  background: linear-gradient(to bottom left, var(--color) 50%, #0000 50.1%) top
      right,
    linear-gradient(to top right, var(--color) 50%, #0000 50.1%) bottom left;
  background-size: calc(var(--slant) + 1.3 * var(--border))
    calc(var(--slant) + 1.3 * var(--border));
  background-repeat: no-repeat;
  box-shadow: 0 0 0 200px inset var(--s, #0000),
    0 0 0 var(--border) inset var(--color);
  clip-path: polygon(
    0 0,
    calc(100% - var(--slant)) 0,
    100% var(--slant),
    100% 100%,
    var(--slant) 100%,
    0 calc(100% - var(--slant))
  );
  transition: color var(--t, 0.3s), background-size 0.3s;
}
.boton.five:focus-visible {
  outline-offset: calc(-1 * var(--border));
  outline: var(--border) solid #fff;
  clip-path: none;
  background-size: 0 0;
}
.boton.five:hover,
.boton.five:active {
  background-size: 100% 100%;
  color: #fff;
  --t: 0.2s 0.1s;
}
.boton.five:active {
  --s: #fff;
  transition: none;
}


/* --------------------------------- */

.boton.six {
  font-size: 1rem;
  padding: 1rem 2.5rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  cursor: pointer;
  text-transform: uppercase;
  background-color: rgb(14, 14, 26);
  color: rgb(234, 234, 234);
  font-weight: 700;
  transition: 0.6s;
  box-shadow: 0px 0px 60px #FF5A21;
  -webkit-box-reflect: below 10px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
}

.boton.six:active {
  scale: 0.92;
}

.boton.six:hover {
  background: rgb(2,29,78);
  background: linear-gradient(270deg, rgba(236, 121, 7, 0.681) 0%, rgba(233, 135, 6, 0.899) 60%);
  color: rgb(4, 4, 38);
}